<template>
	<div
		class="frameWrap"
		style="width: 100%; height: 100%">
		<v-overlay
			v-if="loading"
			color="black"
			z-index="20"
			:value="searchFocused">
			<v-progress-circular
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<iframe
			@load="loading = false"
			frameBorder="0"
			width="100%"
			class="log-frame"
			height="100%"
			style="position: absolute"
			allow="clipboard-read; clipboard-write"
			:src="source"></iframe>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			loading: true,
			session: this.$route.query.session,
			round: this.$route.query.round,
			casino: this.$route.query.casino,
			game: this.$route.query.game,
			mode: this.$route.query.mode,
			isStepSlot: this.$route.query.step,
			orientation: this.$route.query.orientation,
			device: this.$route.query.device,
			lang: this.$route.query.lang,
		};
	},
	computed: {
		...mapGetters(["historyUrl", "gamesUrl"]),
		source() {
			const langString = this.lang ? "&language=" + this.lang : "";
			if (this.mode === "history") {
				if (this.isStepSlot) {
					return `${this.historyUrl}/${this.casino}/${this.session}/cycle/${this.round}?lang=en&support`;
				}
				return `${this.historyUrl}/${this.casino}/session/${this.session}/rounds/${this.round}?lang=en&support`;
			} else {
				return `${this.gamesUrl}/${this.game}/?mode=replay&casino_id=${
					this.casino
				}&replay_round=${this.round}&replay_session=${this.session}&replay_ui=${
					this.device === "2" || this.device === "3" ? "mobile" : "desktop"
				}${langString}`;
			}
		},
	},
};
</script>

<style lang="sass">

@for $i from 1 through 3
	.l#{$i}>svg
		$dur:4s
		$offset: .3
		$delay: ($i * $offset) - 3s
		animation-duration: $dur
		animation-delay: $delay
		animation-direction: alternate
		animation-timing-function: ease-in-out
		@if $i % 2 == 0
			animation-direction: alternate-reverse
		.v-progress-circular__overlay
			animation-duration: $dur
			animation-delay: $delay
			animation-direction: alternate
			animation-timing-function: ease-in-out
			@if $i % 2 == 0
				animation-direction: alternate-reverse

*:not(.iOS)
	&::-webkit-scrollbar
		width: 0px
		height: 0px

.frameWrap
	background-color: black !important
	overflow: hidden
</style>
