var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "frameWrap",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _vm.loading
        ? _c(
            "v-overlay",
            {
              attrs: {
                color: "black",
                "z-index": "20",
                value: _vm.searchFocused,
              },
            },
            [
              _c(
                "v-progress-circular",
                {
                  staticClass: "l2",
                  attrs: { indeterminate: "", size: "100", color: "primary" },
                },
                [
                  _c("v-progress-circular", {
                    staticClass: "l3",
                    attrs: { indeterminate: "", size: "75", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("iframe", {
        staticClass: "log-frame",
        staticStyle: { position: "absolute" },
        attrs: {
          frameBorder: "0",
          width: "100%",
          height: "100%",
          allow: "clipboard-read; clipboard-write",
          src: _vm.source,
        },
        on: {
          load: function ($event) {
            _vm.loading = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }